import { createStore, applyMiddleware, combineReducers } from "redux";
import promiseMiddleware from "redux-promise";
import { createLogger } from "redux-logger";
import { apiMiddleware } from "redux-api-middleware";
import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";
import { map } from "lodash";

import app from "reducers/app";
import theme from "components/administration/designManager/themeSlice";
import groupCategoryTheme from "components/administration/designManagerLight/groupCategoryThemeSlice";
import groupTheme from "components/administration/designManagerLight/groupThemeSlice";
import invites from "reducers/invites";
import participations from "reducers/participations";
import participantsRequests from "reducers/participantsRequests";
import navigation from "reducers/navigation";
import groups from "reducers/groups";
import appCreator from "reducers/appCreator";
import memberships from "reducers/memberships";
import folders from "reducers/folders";
import meetingRooms from "reducers/meetingRooms";

import administrationWidgetbarReducer from "reducers/administration/widgetbar";
import administrationLegalTextReducer from "reducers/administration/legalText";
import indexBy from "helpers/indexBy";
import rootSaga from "./sagas";

const defaultReducers = {
  app,
  theme,
  groupCategoryTheme,
  groupTheme,
  network: (state = null) => state, // from init
  groups,
  currentMember: (state = null) => state, // from init
  memberships,
  form: formReducer,
  appCreator,
  navigation,
  invites,
  participations,
  folders,
  appointments: combineReducers({
    participantsRequests: participantsRequests,
  }),
  administration: combineReducers({
    widgetbar: administrationWidgetbarReducer,
    legalText: administrationLegalTextReducer,
  }),
  meetingRooms,
};

// Useful in storybook where there is no Preload
const Preload = window.Preload || {
  current_network: {},
  my_groups: [],
  current_member: {},
};

const initialState = {
  network: Preload.current_network,
  groups: {
    bySlug: indexBy(Preload.my_groups, "slug"),
    myGroupSlugs: map(Preload.my_groups, "slug"),
  },
  currentMember: Preload.current_member,
};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  apiMiddleware,
  promiseMiddleware, // Deprecated! Dont use and remove when it is not required anymore
  sagaMiddleware,
];

/* Run sessionStorage.setItem('debug', true) to enable redux logger output */
if (sessionStorage.getItem("debug")) {
  const loggerMiddleware = createLogger({ collapsed: true });
  middlewares.push(loggerMiddleware);
} else {
  console.log(
    "redux-logger output is disabled, run `sessionStorage.setItem('debug', true)` to enable",
  );
}
const store = createStore(
  combineReducers(defaultReducers),
  initialState,
  applyMiddleware(...middlewares),
);

// Use this to run a saga from a custom pack or something optional (like the chat)
store.runSaga = sagaMiddleware.run;
store.runSaga(rootSaga);

// Use this to add reducers from a custom pack or something optional (like the chat)
store.currentReducers = defaultReducers;
store.addReducers = (reducerMap) => {
  store.currentReducers = { ...store.currentReducers, ...reducerMap };
  store.replaceReducer(combineReducers(store.currentReducers));
};

export default store;
