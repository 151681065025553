import { isEmpty } from "lodash";

export const appsApiBaseUrl = () => "/api/apps";
export const appUrl = ({ externalAppId, appId }) => {
  if (isEmpty(externalAppId) && isEmpty(appId))
    throw new Error("appId or externalAppId required to build appUrl");

  return [
    appsApiBaseUrl(),
    "apps",
    externalAppId ? `external/${externalAppId}` : appId,
  ].join("/");
};

export const itemUrl = ({ appId, externalAppId, itemId, externalItemId }) => {
  if (isEmpty(itemId) && isEmpty(externalItemId))
    throw new Error("itemId or externalItemId required to build itemUrl");

  return [
    appUrl({ appId, externalAppId }),
    "items",
    externalItemId ? `external/${externalItemId}` : itemId,
  ].join("/");
};
