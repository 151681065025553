import type { ModalActionsProps } from "./modal/ModalActions";
import React from "react";
import ReactDOM from "react-dom";
import { isEmpty } from "lodash";
import classnames from "classnames";
import BootstrapModal from "bootstrap/js/dist/modal";
import ModalActions from "./modal/ModalActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

let id = 0;

type ModalProps = {
  title?: string;
  static?: boolean;
  fullscreen?: boolean;
  onClose?(...args: unknown[]): unknown;
  resizeCallback?(...args: unknown[]): unknown;
  modalClassName?: string;
  children?: React.ReactNode;
  modalActionProps?: ModalActionsProps;
};

class Modal extends React.Component<ModalProps> {
  el: HTMLDivElement;
  static Spinner: (args: SpinnerProps) => JSX.Element;
  shown: boolean | undefined;
  hiding: boolean | undefined;
  modal: BootstrapModal | undefined;
  private readonly id: string;
  private modalEl: Element | undefined;

  constructor(props: ModalProps) {
    super(props);
    this.id = `modal-${id++}`;
    // React renders into this el
    this.el = document.createElement("div");
    this.el.id = this.id + "-root";
  }

  componentDidMount() {
    const backdropExists =
      document.body.getElementsByClassName("modal-backdrop").length > 0;

    this.modalEl = this.el.children[0];
    this.modal = new BootstrapModal(this.modalEl, {
      backdrop: this.props.static ? "static" : !backdropExists,
    });
    this.modal.show();

    this.modalEl.addEventListener("shown.bs.modal", () => (this.shown = true));
    this.modalEl.addEventListener("hide.bs.modal", () => (this.hiding = true));
    this.modalEl.addEventListener("hidden.bs.modal", (e) => {
      this.hiding = false;
      this.shown = false;
      this.props.onClose?.(e);
    });

    if (this.props.fullscreen) {
      window.addEventListener("resize", this.onResize);
      this.onResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    // This is critical because bootstrap ignores method calls during transitions
    // https://getbootstrap.com/docs/5.3/components/modal/#methods
    if (this.shown) {
      this.remove();
    } else {
      this.modalEl?.addEventListener("shown.bs.modal", this.remove);
    }
  }

  remove = () => {
    if (!this.hiding) {
      this.hiding = true;
      this.modal?.hide();
      this.modalEl?.addEventListener("hidden.bs.modal", () => {
        this.modal?.dispose();
        this.el.remove();
      });
    }
  };

  onResize = () => {
    const modalBodyHeight = ($(window).height() as number) - 161; // 60px margin, 71px header, 30px padding

    $(this.el)
      .find(".modal-body")
      .css({
        height: `${modalBodyHeight}px`,
        maxHeight: `${modalBodyHeight}px`,
      });

    if (this.props.resizeCallback) {
      this.props.resizeCallback($(this.el), modalBodyHeight);
    }
  };

  render() {
    const header: Array<React.ReactNode> = [];
    if (!isEmpty(this.props.title))
      header.push(
        <h5 id={this.id + "-label"} className="modal-title" key="title">
          {this.props.title}
        </h5>,
      );

    header.push(
      <button
        type="button"
        className="btn btn-light mr-1"
        data-bs-dismiss="modal"
        aria-label="Close"
        key="close"
      >
        <FontAwesomeIcon icon={solid("xmark")} />
      </button>,
    );

    const modal = (
      <div
        id={this.id}
        className={classnames(
          "modal fade singleton-modal",
          this.props.modalClassName,
        )}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={this.id + "-label"}
        aria-hidden="true"
      >
        <div
          className={classnames(
            "modal-dialog modal-lg modal-dialog-centered tixxt-modal-body p-2 md:p-10",
            { "modal-fullscreen": this.props.fullscreen },
          )}
        >
          <div className="modal-content">
            {!isEmpty(header) ? (
              <div className="modal-header">{header}</div>
            ) : null}
            <div className="modal-body">{this.props.children}</div>
            {!isEmpty(this.props.modalActionProps) ? (
              <ModalActions {...(this.props.modalActionProps || {})} />
            ) : null}
          </div>
        </div>
      </div>
    );

    document.body.appendChild(this.el);
    return ReactDOM.createPortal(modal, this.el, this.id);
  }
}

Modal.Spinner = Spinner;

type SpinnerProps = {
  label: string;
};

function Spinner({ label }: SpinnerProps) {
  return (
    <div
      style={{
        minHeight: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <i className="fa fa-spinner fa-pulse fa-5x fa-fw" />
      <span className="sr-only">{label || "Loading"}</span>
      {label ? <div style={{ marginTop: 16 }}>{label}</div> : null}
    </div>
  );
}

export default Modal;
