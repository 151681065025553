import React, { MouseEventHandler } from "react";

export type ModalActionsProps = {
  closeModal?: MouseEventHandler<HTMLAnchorElement>;
  submitText?: string;
  submit?: MouseEventHandler<HTMLAnchorElement>;
};

function ModalActions({ closeModal, submitText, submit }: ModalActionsProps) {
  return (
    <div className="form-actions modal-footer">
      <a href="#" onClick={closeModal} className="btn btn-link">
        {I18n.t("js.cancel")}
      </a>
      <a href="#" onClick={submit} className="btn btn-primary">
        {submitText ? I18n.t(submitText) : ""}
      </a>
    </div>
  );
}

export default ModalActions;
