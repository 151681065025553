import { get } from "lodash";
import { groupSelector, networkSelector } from "./environment";

// Select the currentFolder from state
export const currentFolderMetaSelector = (state) =>
  get(state, ["folders", "currentFolder"]);
// Select the currentFolderId from state
export const currentFolderIdSelector = (state) =>
  get(currentFolderMetaSelector(state), ["id"]);

// Select all by id indexed folders
export const foldersByIdSelector = (state) => get(state, ["folders", "byId"]);

// Select the current folder from list via stored currentFolderId
export const currentFolderSelector = (state) =>
  get(foldersByIdSelector(state), [currentFolderIdSelector(state)]);

// Selector creator to select a folder by given id
export const createFolderSelectorById = (id) => (state) =>
  get(foldersByIdSelector(state), [id]);

export const rootFolderIdSelector =
  ({ groupSlug }) =>
  (state) =>
    get(
      groupSlug ? groupSelector(state, groupSlug) : networkSelector(state),
      "root_folder_id",
    );
