import {
  isEmpty,
  map,
  filter,
  pick,
  mapValues,
  compact,
  castArray,
  get,
} from "lodash";

import { selectAppResult } from "selectors/appCreator";
import { updateItem, createItem } from "actions/appCreator";
import publishAllVolatile from "helpers/files/publishAllVolatile";
import handleItemApiError from "./handleItemApiError";

const pickFileValues = (values, fields) =>
  mapValues(pick(values, fields), (files) => compact(castArray(files)));

const persistItem = async (
  store,
  { appId, externalAppId, itemId, externalItemId, values },
) => {
  const dispatch = store.dispatch;
  const { data: app } = selectAppResult(store.getState());

  if (
    app.id !== appId &&
    (isEmpty(externalAppId) || app.external_id !== externalAppId)
  )
    throw "Can't persist item for app which is not currently in state";

  const fileFields = map(filter(app.properties, { type: "file" }), "name");
  const imageFields = map(filter(app.properties, { type: "image" }), "name");

  const publishedFiles = await publishAllVolatile({
    dispatch,
    values: pickFileValues(values, fileFields),
    storageDirectory: "files",
  });

  const publishedImages = await publishAllVolatile({
    dispatch,
    values: pickFileValues(values, imageFields),
    storageDirectory: "images",
  });

  const shouldCreateItem = isEmpty(externalItemId) && isEmpty(itemId);

  const createOrUpdate = shouldCreateItem ? createItem : updateItem;

  const itemResult = await dispatch(
    createOrUpdate({
      appId,
      externalAppId,
      itemId,
      externalItemId,
      body: { values: { ...values, ...publishedFiles, ...publishedImages } },
    }),
  );

  if (itemResult.error) {
    handleItemApiError(get(itemResult, ["payload", "response", "errors"]));
  }
  return itemResult.payload;
};

export default persistItem;
