import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import useApiResource from "components/shared/hooks/useApiResource";
import { fetchFolder, selectFolder } from "actions/files";
import {
  currentFolderMetaSelector,
  currentFolderSelector,
} from "selectors/folders";
import Breadcrumb from "../Breadcrumb";

function useFolderResource({ currentRootFolder }) {
  useEffect(() => {
    dispatch(
      selectFolder({
        id: currentRootFolder.id,
        namespace: currentRootFolder.namespace,
      }),
    );
  }, [currentRootFolder.id]);

  const currentFolderMeta = useSelector(currentFolderMetaSelector);

  const { data: currentFolder, loading } = useApiResource({
    actionCreator: fetchFolder,
    payload: {
      id: get(currentFolderMeta, ["id"], currentRootFolder.id),
      namespace: get(
        currentFolderMeta,
        ["namespace"],
        currentRootFolder.namespace,
      ),
    },
    selector: currentFolderSelector,
  });

  const dispatch = useDispatch();

  const handleNavigate = ({ id, namespace }) => {
    dispatch(selectFolder({ id, namespace }));
  };

  const breadcrumb = (
    <Breadcrumb folder={currentFolder} handleNavigate={handleNavigate} />
  );

  return { loading, currentFolder, handleNavigate, breadcrumb };
}

export default useFolderResource;
