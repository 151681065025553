import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { RSAA } from "redux-api-middleware";

// Hook to load data via given actionCreator(payload)
// and return value from given selector
//
// Only fires action again when values in the payload changed
// and the result is not yet in state (based on payload again)
//
// @example
//   const { loading, data: propertySet } = useApiResource({
//     actionCreator: fetchPropertySet,
//     payload: { name, contextId, contextType },
//     selector: selectFolderProfilePropertySet,
//   });
function useApiResource({ actionCreator, payload, selector, loadData = true }) {
  const resource = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAlreadyLoadedOrLoading(resource, payload) || !loadData) return;

    const action = actionCreator(payload);
    configureBailout(action, selector, payload);
    dispatch(action);
  }, Object.values(payload));

  return resource || {};
}

// Configures a bailout for the request when resources isAlreadyLoadedOrLoading
// This can happen when two components using the same api resource are mounted simultaneously
const configureBailout = (action, selector, payload) => {
  if (action[RSAA].bailout) {
    console.warn(
      "Action created by useApiResource hook already contained bailout config, can't inject deduplication logic.",
    );
    return;
  }

  action[RSAA].bailout = (state) => {
    const resource = selector(state);
    return isAlreadyLoadedOrLoading(resource, payload);
  };
};

// Is true if the a resource is available
// and it's meta value matches the request payload
//
// Prevents an in-flight request from being repeated with the same params
// or an already loaded resource from being reloaded
const isAlreadyLoadedOrLoading = (resource, payload) =>
  !isEmpty(resource) &&
  isEqual(Object.values(resource.meta), Object.values(payload));

export default useApiResource;
